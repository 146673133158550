<script setup lang="ts">
  const props = defineProps<{
    menuName?: string;
  }>();
</script>
<template>
  <div class="navbar-logo">
    <a v-if="props?.menuName !== 'james-hardie'" class="navbar-brand ms-2" href="/" aria-label="Navbar">
      <img src="/Worthington-white-02.svg" alt="Worthington Homes" height="40" width="200" />
    </a>
    <div class="d-flex flex-nowrap align-items-center" v-else>
      <a class="navbar-brand ms-2 me-0" href="/" aria-label="Navbar">
        <NuxtImg class="jh-wh-logo" src="/Worthington-white-02.svg" alt="Worthington Homes" height="40" width="220"/>
        <span class="logo-separator"></span>
      </a>
      <a class="navbar-brand" href="/designed-for-living/" aria-label="Navbar">
        <img class="jh-logo" src="/james-hardie-vector-logo.svg" alt="James Hardie" height="40" width="200">
      </a>
    </div>
  </div>
</template>

<style lang="css" scoped>
.logo-separator {
  margin-left: 1.3rem !important;
  margin-right: 1rem !important;
}
.jh-wh-logo,
.jh-logo {
  transition: height 0.3ms ease-in-out;
}
@media (max-width: 767.98px) {
  .jh-wh-logo,
  .jh-logo {
    max-width: 130px;
  }
}
@media (min-width: 1200px) {
  .jh-wh-logo,
  .jh-logo {
    width: auto !important;
    height: 25px !important;
  }
}
@media (min-width: 1300px) {
  .jh-wh-logo,
  .jh-logo {
    width: auto !important;
    height: 35px !important;
  }
}
@media (min-width: 1400px) {
  .jh-wh-logo,
  .jh-logo {
    width: auto !important;
    height: 40px !important;
  }
}
</style>
